// 获取好友列表
export const getFriends 			= (params) => window._http('/Friends-index', 										params)

// 获取淘能量数和可拆红包数
export const getTnlCount 			= (params) => window._http('/Friends-getTnlCount', 							params)

// 获取邀请好友链接
export const getShareLink 		= (params) => window._http('/Invitation-getShareLink', 					params)

// 获取好友淘能量列表
export const getTnlFriends 		= (params) => window._http('/Friends-getTnlFriends', 						params)

// 开红包
export const openRedpackage 	= (params) => window._http('/Invitation-openNewUserRedpackage', params)

// 获取好友列表
export const getFriendsCount 	= (params) => window._http('/Friends-getCount', 								params)

// 检验邀请code
export const checkInviteCode 	= (params) => window._http('/Invitation-checkInviteCode', 						params)

// 跳过邀请码
export const  ignoreCode = (params) => window._http('/Invitation-ignoreCode', 						params) 

export const getInviteCode = (params) => window._http('/Invitation-getRegisterInviteCode', params)