<template>
	<div class="page-container">
		<div class="wrapper">
			<div class="banner">
				<img src="http://img.taojiefang.cn/FgqgzI_j8o1RPOOKBBFLR0bkLGio" alt="淘街坊" />
			</div>
			<div class="tc f22 p20">
				填写邀请码
			</div>
			<div class="tc">
				<span id="invitecode">{{ invitecode }}</span>
			</div>
			<div class="tc">
				<button id="copyma" class="copyanniu" v-clipboard:copy="invitecode" @success="onCopy">复制邀请码</button>
			</div>
			<div class="f20" id="shuoming">
				<ul>
					<li class="line-text">
						<img src="@/assets/pc_images/success.png" class="gou"> 
						<span>应用商店搜索 <font class="cheng">「淘街坊」</font></span>						
					</li>
					<li class="line-text">
						<img src="@/assets/pc_images/success.png" class="gou">
						<span>自购/分享：可多获百分之90奖励</span>
					</li>
				</ul>
			</div>
			<div class="footer banner">
				<img src="http://img.taojiefang.cn/FrH3NO40E693j0NL3KAGM6uH8u8D" alt="淘街坊"/>
			</div>
			<div class="moblie_footer" @click="_golink">
				<span>立即注册</span>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import {getInviteCode} from '@/api/invite'
import VueClipboards from "vue-clipboards"
import VueMeta from 'vue-meta'

Vue.use(VueClipboards).use(VueMeta)

export default {
	components: {},
	metaInfo: {
		title: '淘街坊邀请码',
		meta: [
			{name: 'keywords', content: '街坊,淘街坊,淘街坊APP,淘街坊邀请码'},
			{name: 'description', content: '淘街坊注册邀请码获取，简单复制粘贴三秒钟即可搞定淘街坊的邀请码注册。限时加入淘街坊还送VIP，自己购买或者分享给朋友，可得90%的优惠哦！'},
		]
	},
	data () {
		return {
			invitecode: ''
		}
	},
	computed: {},
	created () {},
	mounted () {
		document.dispatchEvent(new Event('render-event'))
		this._loadData()
	},
	methods: {
		_loadData() {
			let params = {
				key: this.$route.query.key
			}
			getInviteCode(params).then(res => {
				if(res.errCode === 0) {
					this.invitecode = res.data.invite_code
				}
			})
		},
		onCopy() {
			alert('邀请码复制成功！去注册淘街坊吧。')
		},
		_golink() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        var ios_link =
          "https://apps.apple.com/cn/app/%E6%B7%98%E8%A1%97%E5%9D%8A/id1444751745";
        window.location.href = ios_link;
        return "ios";
      } else {
        var andriod_link =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
        window.location.href = andriod_link;
        return "andriod";
      }
    },
	}
}
</script>

<style scoped>

.f22 {
	font-size: 22px;
}

.p20 {
	padding: 20px;
}

.mt10 {
	margin-top: 10px;
}

.page-container {
	padding: 0;
	margin: 0;
}

.tc {
	text-align: center;
}

.wrapper {
	margin: 0 auto;
	background-color: #FDDAD7;
}

.banner {
	font-size: 0;
}

.banner img {
	width: 100%;
}

#invitecode {
	padding: 3px;
	border-bottom: 1px #aaa solid;
	border-top: 1px #aaa solid;
	font-weight: bold;
	font-size: 40px;
}

.copyanniu {
	width: 250px;
	margin-top: 20px;
	text-decoration: none;
	border-radius: 15px;
	padding: 18px;
	font-size: 22px;
	color: #FFF;
	border: 0;
	background-color: #F34443;
	outline: none;
	cursor: pointer;
}

#copyma:active {
	background-color: #c53838;
}
#copyma:focus {
	background-color: #c53838;
}

#shuoming {
	padding: 20px;
}

.gou {
	width: 25px;
	padding-right: 5px;
}

.cheng {
    color: #9d6229;
}

ul {
	display: block;
	list-style-type: disc;
}

li {
	display: flex;
	align-items: center;
	list-style: none;
	line-height: 30px;
	font-size: 20px;
}
.moblie_footer {
  height: 70px;
  background: #F34543;
	display: flex;
	align-items: center;
	justify-content: center;
}
.moblie_footer span {
	font-size: 22px;
	color: #fff;
}
</style>
